:root {
    --base-size: 170px;
    --color-text: #333;
    --color-hover: #900;
    --color-border: #CCC;
    --border-size: 4px;
    --font-size-1: 3rem;
    --font-size-2: 2rem;
    --font-size-3: 1.2rem;
    --font-size-4: 1rem;
    --font-size-5: 0.7rem;
}

.app {
    text-align: center;
}

.app-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-text);
}

.app-logo {
    margin-top: -5vh;
    pointer-events: none;
    width: var(--base-size);
    height: var(--base-size);
    overflow: hidden;
    border-radius: 50%;
    border: var(--border-size) solid var(--color-border);
    position: relative;
}

.app-logo img {
    filter: brightness(1.2);
    position: absolute;
    right: 0;
}

.app-full-name {
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    line-height: 1.3;
    font-size: var(--font-size-3);
    color: var(--color-text);
}

.app-full-name p {
    margin: 15px 0 0;
    line-height: 1;
    font-size: var(--font-size-1);
    text-transform: uppercase;
}

.app-skills p {
    margin: 5px;
}

.app-skills {
    width: var(--base-size);
    margin: 15px auto 20px;
    padding: 8px 0;
    font-size: var(--font-size-5);
    border: var(--border-size) solid var(--color-border);
    border-radius: 3px;
    font-weight: 400;
}

.app-links-icons {
    margin: 0;
    font-size: var(--font-size-3);
}

.app-links {
    line-height: 0;
    font-family: monospace;
}

.app-links a {
    color: var(--color-text);
    text-decoration: none;
}

.app-links-icons a {
    color: var(--color-text);
    transition: all 0.5s ease;
    margin: 0 5px;
}

.app-links-icons a:hover {
    color: var(--color-hover);
}
